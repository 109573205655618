<template>
    <div v-if="!loading">
        <div v-if="this.$store.state.auth.user.role === 'agence'" class="mb-3">
            <router-link to="/agencies"><i class="fas fa-arrow-circle-left" /> Retour</router-link>
        </div>

        <div v-if="this.$store.state.auth.user.role === 'manager'" class="mb-3">
            <router-link to="/agency/managers"><i class="fas fa-arrow-circle-left" /> Retour</router-link>
        </div>

        <div class="box">
            <div class="mb-3">
                <CreateAgencyClientButton @reload="$refs.clients.fetchData()" />
            </div>

            <AgencyClientsTable ref="clients" />
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import AgencyClientsTable from '@/components/Agency/Table/AgencyClientsTable'
    import CreateAgencyClientButton from '@/components/Agency/Button/CreateAgencyClientButton.vue'

    export default {
        title: 'Agence',
        components: {
            CreateAgencyClientButton,
            AgencyClientsTable
        },
        data: () => ({
            totalPaid: 0,
            totalContents: 0,
            loading: true
        }),
        created() {
            this.$store.commit('consultants/setScope', 'consultant')

            if (this.$store.state.auth.user.role === 'consultant') {
                this.getAgency()
            } else {
                this.getAgencyFromUser()
                    .then(data => {
                        if (data.agency) {
                            this.$store.commit('consultants/setAgency', data.agency)
                        }

                        if (data.manager) {
                            this.$store.commit('consultants/setAgency', {
                                id: data.manager.agency_id
                            })
                            this.$store.commit('consultants/setManager', data.manager)
                        }

                        this.$store.commit('consultants/setConsultant', {
                            id: this.$route.query.id
                        })

                        this.fetch()
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
            }
        },
        computed: {
            ...mapState('consultants', ['consultant']),
            month: {
                get() {
                    return this.$store.state.consultants.month
                },
                set(value) {
                    this.$store.commit('consultants/setMonth', value)
                    this.fetch()
                }
            }
        },
        methods: {
            ...mapActions('consultants', ['getConsultant', 'getAgencyFromUser']),
            getAgency() {
                this.getAgencyFromUser()
                    .then(data => {
                        this.$store.commit('consultants/setAgency', {
                            id: data.consultant.agency_id
                        })
                        this.$store.commit('consultants/setManager', {
                            id: data.consultant.manager_id
                        })
                        this.$store.commit('consultants/setConsultant', data.consultant)

                        this.fetch()
                    })
                    .catch(e => {
                        console.log(e)
                        this.$store.dispatch('toastError', e)
                    })
            },
            fetch() {
                this.loading = true
                this.getConsultant(this.consultant.id)
                    .then(() => {
                        this.setTitle(`Consultant : ${this.consultant.firstname} ${this.consultant.lastname}`)
                    })
                    .catch(e => {
                        this.$store.dispatch('toastError', e)
                    })
                    .finally(() => {
                        this.loading = false
                    })
            }
        }
    }
</script>

<style></style>
